/** @jsx jsx */
/** @jsxFrag */
import { jsx } from '@emotion/react';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { connect, Provider, useSelector } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
  Outlet,
} from 'react-router-dom';

import { setSession } from './actions';
import MythosReducer from './reducers';

import AllCardsPage from './AllCardsPage';
import LobbyListPage from './LobbyListPage';
import LoginPage from './LoginPage';
import { startChannelListener } from './ChannelListener';
import GamePage from './GamePage';
import LobbyPage from './LobbyPage';
import Session from './Session';
import GameHistoryListPage from './GameHistoryListPage';
import PageHeaderView from './PageHeaderView';
import RulesPage from './RulesPage';

import '../assets/css/style.css';
import PrintCardsPage from './PrintCardsPage';
import CardDebugPage from './CardDebugPage';
import LogoBanner from './LogoBanner';
// unused but necessary for this asset to be bundled
import Favicon from '../assets/favicon.png';

function select_session(state: any) {
  return { session: state.session as Session | undefined };
}

var MainPage = connect(select_session)(
  class extends React.Component<{ session?: Session }> {
    render() {
      var session = this.props.session;
      const params = new URLSearchParams(window.location.search);
      const redirect = params.get('redirect');

      if (session && redirect) {
        return <Navigate to={redirect} replace />;
      }

      var loginElement = !session ? (
        <LoginPage {...this.props} redirect={redirect} />
      ) : (
        <LobbyListPage {...this.props} />
      );

      return (
        <div>
          <PageHeaderView session={this.props.session} />
          <LogoBanner />
          {loginElement}
        </div>
      );
    }
  },
);

class NotFoundHandler extends React.Component {
  render() {
    return <p>Page not found</p>;
  }
}

function RequireSession({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const session = useSelector(
    (state: any) => state.session as Session | undefined,
  );

  if (!session) {
    return (
      <Navigate
        to={`/?redirect=${encodeURIComponent(location.pathname)}`}
        replace
      />
    );
  }

  return children;
}

window.onload = () => {
  const json_session = window['__session' as any];
  const session = json_session ? Session.fromJSON(json_session) : null;

  let store = applyMiddleware(thunk)(createStore)(MythosReducer);
  store.dispatch(setSession(session));

  startChannelListener(store, session);

  let container = document.getElementById('content');
  if (!container) {
    throw new Error('No element with id "content"');
  }
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/login" element={<LoginPage />} />

            <Route
              element={
                <RequireSession>
                  <Outlet />
                </RequireSession>
              }
            >
              <Route path="/game/:gameID" element={<GamePage />} />
              <Route path="/lobby/:lobbyID" element={<LobbyPage />} />
              <Route path="/lobby-list" element={<LobbyListPage />} />
            </Route>

            <Route
              path="/game-history-list"
              element={<GameHistoryListPage />}
            />
            <Route path="/all-cards" element={<AllCardsPage />} />
            <Route path="/rules" element={<RulesPage />} />
            <Route path="/print" element={<PrintCardsPage />} />
            <Route path="/debug_card" element={<CardDebugPage />} />
            <Route path="*" element={<NotFoundHandler />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </StrictMode>,
  );
};
