import { countBy } from 'underscore';

import * as Rules from './Rules';
import { InflatedPlayer } from './Game';

export function cardCompositionCounts(player: InflatedPlayer) {
  let board = player.cards;
  let counts = countBy(board, (card) => card.type);
  board.forEach((card) => {
    counts[card.subType] = (counts[card.subType] || 0) + 1;
  });
  return counts;
}

export function totalProductionCounters(context: Rules.Context) {
  const production_context = Rules.handleProduction(context);
  return production_context.production_delta;
}

export type CardCostTuple = [Rules.CardWithID, number];
export function affordableCards(
  context: Rules.Context,
  cards: Rules.CardWithID[],
): {
  affordable: CardCostTuple[];
} {
  // XXX: this function only accounts for gold, not other resources

  const baseCounts = Rules.computeBaseCounters(context);

  let card_cost_tuples: CardCostTuple[] = cards.map((card) => {
    let cost = Math.max(0, card.cost - baseCounts.gold);
    return [card, cost];
  });

  const affordable = card_cost_tuples.filter(([_, cost]) => {
    return cost <= context.player.counters.gold;
  });

  return { affordable };
}

export function endOfGameFavor(context: Rules.Context): {
  favor: number;
  triggeredCardIDs: string[];
} {
  let newContext = Rules.handleEndOfGameEffects(context);
  return {
    favor: newContext.production_delta.favor,
    triggeredCardIDs: newContext.triggeredCardIDs,
  };
}
