/** @jsx jsx */
import { jsx, css, SerializedStyles } from '@emotion/react';

import React from 'react';

type Card = any;
export type HoverCardRenderer = (args: { card: Card }) => React.ReactElement;
export type HoverCardListener = (card: Card | null) => void;

// Offset means it is offset from the logview
export type HoverCardPosition =
  | 'bottom-right-offset'
  | 'bottom-right'
  | 'top-right-offset';

class HoverCardStore {
  private hoveredCard_: Card | null = null;
  private mouseX_: number = 0;
  private mouseY_: number = 0;
  private listeners_: HoverCardListener[] = [];
  private position_: HoverCardPosition = 'bottom-right';
  private renderer_: HoverCardRenderer | null = null;
  constructor() {}
  // a listener is a function of 1 argument, a Card
  addListener(listener: HoverCardListener): void {
    this.listeners_.push(listener);
    listener(null);
  }
  removeListener(listener: HoverCardListener): void {
    this.listeners_ = this.listeners_.filter((x) => x !== listener);
  }
  getHoveredCard(): Card | null {
    return this.hoveredCard_;
  }
  getRenderer(): HoverCardRenderer | null {
    return this.renderer_;
  }
  getHoverCardComponent(): React.ReactElement {
    return <HoverCard scale={2.5} store={this} />;
  }
  getMouseX(): number {
    return this.mouseX_;
  }
  getMouseY(): number {
    return this.mouseY_;
  }

  // internal
  setCard(card: Card | null, renderer: HoverCardRenderer | null): void {
    this.hoveredCard_ = card;
    this.renderer_ = renderer;
    this.notifyListeners_();
  }
  setMousePosition(x: number, y: number): void {
    this.mouseX_ = x;
    this.mouseY_ = y;
    this.notifyListeners_();
  }
  notifyListeners_(): void {
    this.listeners_.forEach((listener) => {
      listener(this.hoveredCard_);
    });
  }
  setPosition(position: HoverCardPosition): void {
    this.position_ = position;
  }
  getPosition(): HoverCardPosition {
    return this.position_;
  }
}

interface Props {
  store: HoverCardStore;
  scale: number;
}
class HoverCard extends React.Component<Props> {
  onHoverCardChange_ = () => {
    this.forceUpdate();
  };

  componentDidMount() {
    this.props.store.addListener(this.onHoverCardChange_);
  }

  componentWillUnmount() {
    this.props.store.removeListener(this.onHoverCardChange_);
  }

  render() {
    // card is 83 x 110px @ 1x
    var card = this.props.store.getHoveredCard();
    var renderer = this.props.store.getRenderer();
    var rendered_card = card && renderer ? renderer({ card: card }) : null;
    var xpos = this.props.store.getMouseX();
    var ypos = this.props.store.getMouseY();
    var scale = 1;
    if (this.props.scale !== null) {
      scale = this.props.scale;
    }
    var style: React.CSSProperties = {
      transform: 'scale(' + scale + ',' + scale + ')',
    };
    if (xpos !== null && ypos !== null) {
      style['position'] = 'fixed';
      style['left'] = xpos + 41.5 * scale;
      style['top'] = ypos + 55 * scale;
    }
    // DISABLE INLINE STYLE FOR NOW
    style = {};

    return (
      <div
        css={[
          HoverCardStyles.hoverCard,
          HoverCardPositionStyles[this.props.store.getPosition()],
        ]}
        style={style}
      >
        {rendered_card}
      </div>
    );
  }
}

const HoverCardStyles = {
  hoverCard: css({
    position: 'fixed',
    zIndex: 1000,
    bottom: '25%',
    right: '9%',
    transform: 'scale(2.5)',
    pointerEvents: 'none',
  }),
};
const HoverCardPositionStyles: Record<HoverCardPosition, SerializedStyles> = {
  'bottom-right-offset': css({
    right: 450,
  }),
  'bottom-right': css({}),
  'top-right-offset': css({
    top: '10%',
    bottom: 'auto',
    right: 375,
  }),
};

export default new HoverCardStore();
