/** @jsx jsx */
/** @jsxFrag */
import { jsx, css } from '@emotion/react';
import React, { ReactElement, useCallback } from 'react';

import { CardSubType, CardType } from '../game/CardTypes';
import * as Rules from '../game/Rules';

import { BasicCardRenderer, CardRenderer } from './CardView';
import Game, { InflatedGame, InflatedPlayer } from '../game/Game';
import HoverCardStore from './HoverCardStore';
import { addToMapOfArrays, nonNull } from '../utils/utils';
import _ from 'underscore';
import { Resource } from '../game/Resources';
import { SymbolView, ResourceToSymbol, BaseResourceToSymbol } from './Symbols';
import nullthrows from 'nullthrows';
import { EmptyCardView } from './EmptyCardView';
import { CardDef } from '../game/GameModel';
import {
  AbilityUpgradeCondition,
  abilityUpgradeConditionToString,
  BaseCardAbility,
  getBaseCountersAbility,
  getDraftCardAbility,
  getEachTurnCardAbility,
  getEndOfGameCardAbility,
  getGainOtherCardAbility,
  upgradeConditionMet,
} from '../game/CardAbilities';
import { sum } from '../game/Utility';
import { makeContextFromGame } from '../game/Rules';

const ORDERED_TYPES = [
  CardType.Leader,
  CardType.Basic,
  CardType.Resource,
  CardType.Conflict,
  CardType.Prayer,
];

type PlayerBoardViewProps = {
  player: InflatedPlayer;
  game: InflatedGame;
  name: string;
  className?: string;
  style?: React.CSSProperties;
};
const PlayerBoardView = React.forwardRef<HTMLDivElement, PlayerBoardViewProps>(
  (props, ref) => {
    let { player, game, name } = props;

    const stacks = new Map<string, Rules.CardWithID[]>();
    player.cards.forEach((card) => {
      addToMapOfArrays(stacks, card.subType, card);
    });

    stacks.forEach((stack) => {
      stack.sort(
        (a, b) => ORDERED_TYPES.indexOf(a.type) - ORDERED_TYPES.indexOf(b.type),
      );
    });

    let counter_views = null;
    // let counters = player.counters;
    // const baseCounters = Rules.computeBaseCounters(
    //   Rules.makeContext(player, 1, 1),
    // );
    // counter_views = _.map(counters as {}, (count: number, name: string) => {
    //   return (
    //     <PlayerCounter
    //       key={name}
    //       name={name as Resource}
    //       count={count}
    //       baseCount={baseCounters[name]}
    //     />
    //   );
    // });

    return (
      <div
        css={PlayerBoardStyles.container}
        className={props.className}
        ref={ref}
      >
        <div css={PlayerBoardStyles.header}>
          <div css={PlayerBoardStyles.name}>{name}</div>
          <div css={PlayerBoardStyles.playerCounters}>{counter_views}</div>
        </div>
        <StacksView
          className={props.className}
          stacks={[
            stacks.get(CardSubType.Unit) || [],
            stacks.get(CardSubType.Building) || [],
            stacks.get(CardSubType.Event) || [],
          ].filter(nonNull)}
          player={player}
          game={game}
          renderer={BasicCardRenderer}
          hoverRenderer={BasicCardRenderer}
        />
      </div>
    );
  },
);
export default PlayerBoardView;

export function PlayerCounter(props: {
  name: Resource;
  count: number;
  baseCount?: number;
}) {
  const { name, count, baseCount } = props;
  if (name === 'favor') {
    return null;
  }
  return (
    <div key={name} css={PlayerBoardStyles.counterContainer}>
      <SymbolView
        symbol={ResourceToSymbol[name as Resource]}
        css={PlayerBoardStyles.resourceSymbol}
      />
      {count}
      {baseCount !== undefined && (
        <>
          <SymbolView
            symbol={nullthrows(BaseResourceToSymbol[name])}
            css={PlayerBoardStyles.baseSymbol}
          />
          {baseCount}
        </>
      )}
    </div>
  );
}

function StacksView(props: {
  stacks: Rules.CardWithID[][];
  renderer: CardRenderer;
  hoverRenderer: CardRenderer;
  player: InflatedPlayer;
  game: InflatedGame;
  className?: string;
  style?: React.CSSProperties;
}): ReactElement {
  const onCardEnter = useCallback((card: CardDef) => {
    HoverCardStore.setCard(card, props.hoverRenderer);
    HoverCardStore.setPosition('bottom-right');
  }, []);
  const onCardLeave = useCallback(() => {
    HoverCardStore.setCard(null, null);
  }, []);
  const context = makeContextFromGame(props.player, props.game);

  return (
    <div
      css={PlayerBoardStyles.stacksContainer}
      className={props.className}
      style={{
        ...props.style,
      }}
    >
      {props.stacks.map((stack, i) => {
        return (
          <div key={i} css={PlayerBoardStyles.stackContainer}>
            {stack.length === 0 && <EmptyCardView />}
            {stack.map((c, j) => {
              let marginTop = 0;
              if (j !== stack.length - 1) {
                marginTop = -113;
                if (needsDoubleHeight(c)) {
                  marginTop += 18;
                }
                const conditions: Map<string, AbilityUpgradeCondition> =
                  new Map();
                const abilities = [
                  getBaseCountersAbility(c),
                  getEachTurnCardAbility(c),
                  getDraftCardAbility(c),
                  getGainOtherCardAbility(c),
                ].filter(nonNull);
                abilities.forEach((a) => {
                  a.outputs.forEach((o) => {
                    if (o.upgradeCondition) {
                      const upgradeString = abilityUpgradeConditionToString(
                        o.upgradeCondition,
                      );
                      conditions.set('upgradeString', o.upgradeCondition);
                    }
                  });
                });
                conditions.forEach((condition) => {
                  if (upgradeConditionMet(condition, context)) {
                    marginTop += 18;
                  }
                });
              }

              return (
                <div
                  key={c.id}
                  css={PlayerBoardStyles.cardContainer}
                  style={{ marginTop: marginTop }}
                >
                  {props.renderer({
                    card: c,
                    onCardEnter,
                    onCardLeave,
                  })}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

function needsDoubleHeight(card: CardDef) {
  const gainOther = getGainOtherCardAbility(card);
  const eachTurn = getEachTurnCardAbility(card);
  const base = getBaseCountersAbility(card);
  const endOfGame = getEndOfGameCardAbility(card);

  const tokensInOutput = (a: BaseCardAbility | null) => {
    if (!a) {
      return 0;
    }
    return sum(a.outputs, (o) => o.outputRatio + (o.multiplier ? 1 : 0));
  };

  return (
    (gainOther &&
      eachTurn &&
      tokensInOutput(gainOther) + tokensInOutput(eachTurn) >= 4) ||
    ((gainOther || eachTurn || base) && endOfGame)
  );
}

const PlayerBoardStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  }),

  stacksContainer: css({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'rgb(85, 85, 85)',
    flexGrow: 1,
  }),
  stackContainer: css({
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'flex-end',
    padding: 4,
  }),
  cardContainer: css({
    '&:hover': {
      zIndex: 2,
      transform: 'scale(1.1)',
    },
  }),

  header: css({
    label: 'player-board-header',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    height: 20,
    padding: 2,
    paddingLeft: 8,

    backgroundColor: 'rgb(35, 35, 35)',
    color: 'rgb(230, 230, 230)',
  }),
  name: css({}),
  playerCounters: css({
    display: 'flex',
    paddingLeft: 0,
  }),
  counterContainer: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
    fontSize: 16,
  }),
  resourceSymbol: css({
    height: 20,
    width: 20,
    marginRight: 4,
  }),
  baseSymbol: css({
    height: 15,
    width: 15,
    marginLeft: 4,
    marginRight: 2,
  }),
};
