export const Resources = [
  'gold',
  'favor',
  'military',
  'warTokens',
  'rerollTokens',
] as const;

export type Resource = (typeof Resources)[number];

export const NameToResourceCharacter: Record<Resource, string> = {
  gold: 'G',
  favor: 'V',
  military: 'M',
  warTokens: 'W',
  rerollTokens: 'R',
};

export const CharacterToResourceName: Record<string, Resource> = {
  G: 'gold',
  V: 'favor',
  M: 'military',
  W: 'warTokens',
  R: 'rerollTokens',
};

export const ResourceToDisplayName: Record<Resource, string> = {
  gold: 'Gold',
  favor: 'Favor',
  military: 'Military',
  warTokens: 'Clash',
  rerollTokens: 'Reroll',
};

export enum Token {
  War = 'War',
  Age1 = 'Age1',
  Age2 = 'Age2',
  Age3 = 'Age3',
}
export function isToken(char: string): char is Token {
  return Token.hasOwnProperty(char);
}
export const TokenToDisplayName: Record<Token, string> = {
  War: 'War',
  Age1: 'Age 1',
  Age2: 'Age 2',
  Age3: 'Age 3',
};

export function isResource(s: string): s is Resource {
  return Resources.includes(s as Resource);
}
export function assertResource(s: string): Resource {
  if (isResource(s)) {
    return s;
  }
  throw new Error(`Invalid resource: ${s}`);
}
