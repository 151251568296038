import { GameOptionsKey } from './GameOptions';

export type GameModule = {
  key: string;
  name: string;
  description: string;

  gameOptionsToAdd: GameOptionsKey[];

  replaceCore?: boolean;
  disabled?: boolean;
};

export const GameModules: GameModule[] = [
  {
    key: 'conquest',
    name: 'Conquest',
    description:
      'Introduces Clash tokens, which are gained whenever you Clash with a foe. Spend these to obtain powerful cards, or save them to synergize with cards in later ages.',

    gameOptionsToAdd: ['warTokens'],
    disabled: true,
  },
  {
    key: 'beta',
    name: 'Beta',
    description: 'Testing grounds for card updates.',
    gameOptionsToAdd: [],
  },
];
