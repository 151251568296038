import _ from 'underscore';
import invariant from 'invariant';
import React, { Ref, RefObject, useCallback } from 'react';
import HoverCardStore, { HoverCardRenderer } from './HoverCardStore';
import { css } from '@emotion/react';

type CardEventFunc<TCard> = (card: TCard, e: any) => void;
type BaseCard = { id: string };
type CardRenderer<TCard> = (props: {
  card: TCard;
  ref?: Ref<HTMLDivElement>;
}) => React.ReactElement;
interface Props<TCard extends BaseCard> {
  cards: TCard[];
  renderer: CardRenderer<TCard>;
  hoverRenderer: CardRenderer<TCard>;
  sortFunc?: (a: TCard, b: TCard) => number;
  additionalClassName?: string;
  highlightColorByCardID?: { [k: string]: string };
  onCardClick?: CardEventFunc<TCard>;
  onCardContextMenu?: CardEventFunc<TCard>;
  cardIDToAnimation?: Map<string, HTMLDivElement>;
  cardIndexToAnimation?: Map<number, HTMLDivElement>;
}

export default function PileView<TCard extends BaseCard>(props: Props<TCard>) {
  const onCardEnter = useCallback(
    (card: TCard, _e: any) => {
      const cardToHover = { ...card };
      HoverCardStore.setPosition('bottom-right');
      HoverCardStore.setCard(cardToHover, props.hoverRenderer);
    },
    [props.hoverRenderer],
  );
  const onCardLeave = useCallback((_card: TCard, _e: any) => {
    HoverCardStore.setCard(null, null);
  }, []);

  let { cards, highlightColorByCardID, sortFunc } = props;
  highlightColorByCardID = highlightColorByCardID || {};

  if (sortFunc) {
    cards = _.clone(cards);
    cards.sort(sortFunc);
  }

  let rendered_cards = cards.map((card, i) => {
    invariant(!!card, 'falsey card in pile %s', card);
    let get_card_id = (card: TCard | undefined) => {
      return card && card.id;
    };
    let hovered =
      get_card_id(card) === get_card_id(HoverCardStore.getHoveredCard());
    let highlight_color = highlightColorByCardID[card.id];
    let overlay_style = {
      boxShadow: highlight_color ? `0px 0px 12px 4px ${highlight_color}` : null,
    };
    let card_props = {
      card: card,
      key: card.id,
      onLeftClick: props.onCardClick,
      onRightClick: props.onCardContextMenu,
      onCardEnter: onCardEnter,
      onCardLeave: onCardLeave,
      overlayStyle: overlay_style,
      overlayCSS: CardOverlayStyle,
      ref: (node: HTMLDivElement) => {
        if (node) {
          // console.log('setting card node', card.id);
          props.cardIDToAnimation?.set(card.id, node);
          props.cardIndexToAnimation?.set(i, node);
        } else {
          // console.log('removing card node', card.id);
          props.cardIDToAnimation?.delete(card.id);
          props.cardIndexToAnimation?.delete(i);
        }
      },
    };
    let wrapper_style = _.clone(PileViewStyles.wrapper);

    var rendered_card = (
      <div style={wrapper_style} key={card.id}>
        {props.renderer(card_props as any)}
      </div>
    );

    return rendered_card;
  });
  return <div className="sub-pile">{rendered_cards}</div>;
}

const CardOverlayStyle = css({
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
});

const PileViewStyles = {
  wrapper: {
    display: 'inline-block',

    padding: 4,

    borderTopWidth: '2px',
    borderTopStyle: 'solid',
    borderTopColor: 'transparent',
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'transparent',
  },
  wrapperTitle: {
    display: 'block',

    marginBottom: 3,

    textAlign: 'center',
    fontSize: 9,
    minHeight: 10,
  },
} as const;
